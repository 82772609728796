import React from "react";
import "../styles/contact.css"
const Contact = () => {
  return <div className="contact">
<p className="delete-title">For account deletion </p>
<p>Contact admin at email:ofxpressapp@gmail.com</p>
<p>or </p>
<p>Reach out here +2349034492806</p>
  </div>;
};

export default Contact;
